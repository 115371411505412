<template>
  <v-form ref="form" v-model="validated">
    <v-card>
      <v-card-text>
        <v-text-field
          :loading="loading"
          v-model="suiteId"
          :label="translate('Suite id')"
          :rules="validationRules.identifier({optional: true})"
          :placeholder="suggestedSuiteId"
          :disabled="!isNew"
          />
      </v-card-text>
      <v-card-text>
        <v-autocomplete
          v-model="collectionId"
          :loading="loading"
          :label="translate('Collection')"
          :items="collectionSchemas"
          item-text="label"
          item-value="collectionId"
          outline
          :rules="validationRules.listValue()"
          :disabled="!isNew"
          />
      </v-card-text>

      <v-card-text>
        <schema-editor
          ref="schemaEditor"
          :schema="schema"
          :loading="loading"
          :disable-name="isNew"
          :get-attribute-label="getAttributeLabel"

          @update-schema="updateSchemaUpdate"
          @update-config="updateConfigUpdate"
          />
      </v-card-text>
    </v-card>
  </v-form>
</template>
<script>
import {mapGetters} from 'vuex'
import SchemaEditor from '@/components/schemas/schema-editor.vue'
import sortBy from 'lodash/sortBy'
export default {
  props: ['value', 'loading', 'schema', 'collectionSchemas', 'is-new'],
  data: () => ({
    validated: false,
    suiteId: null,
    collectionId: null,
    schemaUpdate: null,
    configUpdate: null
  }),
  components: {
    SchemaEditor
  },
  watch: {
    validated () {
      this.$emit('input', this.validated)
    },
    schema: {
      immediate: true,
      handler (v) {
        this.collectionId = v.collectionId
        this.suiteId = v.suiteId
      }
    }
  },
  computed: {
    ...mapGetters(['api', 'suitesConfig', 'translate', 'validationRules']),
    suggestedSuiteId () {
      if (!this.collectionId) {
        return
      }
      if (!this.schemaUpdate) {
        return
      }
      // let attributeNames = sortBy(Object.values(this.schema.attributes).map(({name}) => name), n => n)
      let attributeNames = sortBy(Object.values(this.schemaUpdate.attributes).map(({name}) => name), n => n)
      return `${this.collectionId}_with_${attributeNames.join('_')}`
    }
  },
  methods: {
    getAttributeLabel (attribute) {
      return this.suitesConfig.getSuiteAttributeLabel(this.schema.suiteId, attribute)
    },
    addNewAttribute () {
      this.$refs.schemaEditor.addNewAttribute()
    },
    getSchemaUpdate () {
      return this.schemaUpdate
        ? {
          ...this.schemaUpdate,
          suiteId: this.suiteId || this.suggestedSuiteId,
          collectionId: this.collectionId
        }
        : null
    },
    getConfigUpdate () {
      return this.configUpdate
    },
    updateSchemaUpdate (u) {
      this.schemaUpdate = u
    },
    updateConfigUpdate (u) {
      this.configUpdate = u
    }
  }
}
</script>
