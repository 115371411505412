<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        {{translate('New suite schema')}}
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <v-btn
          text
          @click="$refs.schemaEditor.addNewAttribute()">
          <v-icon>add</v-icon>
          {{translate('Add attribute', 'add-attribute')}}
        </v-btn>

        <v-btn
          text
          :disabled="!valid"
          @click="saveSchema()"
          >
          <v-icon>save</v-icon> {{translate('Save', 'save')}}
        </v-btn>
      </v-toolbar-items>
    </template>

    <suite-schema-editor
      ref="schemaEditor"
      v-model="valid"
      :is-new="true"
      :schema="details.schema"
      :collectionSchemas="details.collectionSchemas"
      :loading="details.loading"
      />
  </layout-page>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import SuiteSchemaEditor from './components/suite-schema-editor.vue'

export default {
  data: () => ({
    valid: true
  }),
  components: {
    LayoutPage,
    SuiteSchemaEditor
  },
  computed: {
    ...mapGetters(['api', 'collectionsConfig', 'suitesConfig', 'translate'])
  },
  asyncComputed: {
    details: {
      async get () {
        let schema = {
          collectionId: null,
          suiteId: null,
          attributes: {}
        }

        // Get all collection schemas (including attributes), expanded with labels and sorting
        let collectionSchemas = await this.api.collections.getCollectionSchemas()
        return {
          collectionSchemas: this.collectionsConfig.mapCollectionSchemas(collectionSchemas),
          schema,
          loading: false
        }
      },
      default: {
        collectionSchemas: [],
        schema: {
          collectionId: null,
          suiteId: null,
          attributes: {}
        },
        loading: true
      }
    }
  },
  methods: {
    ...mapActions(['updateClientConfig']),
    getAttributeLabel (attribute) {
      return this.suitesConfig.getSuiteAttributeLabel(this.suiteId, attribute)
    },
    async saveSchema() {
      let schemaUpdate = this.$refs.schemaEditor.getSchemaUpdate()
      if (!schemaUpdate) {
        return
      }
      let configUpdate = this.$refs.schemaEditor.getConfigUpdate()

      await this.api.suites.updateSuiteSchema({update: schemaUpdate, query: {'create-only': this.isNew ? 1 : undefined}})

      if (configUpdate) {
        await this.updateClientConfig({
          suites: {
            [schemaUpdate.suiteId]: {
              attributes: this.configUpdate
            }
          }
        })
      }
      this.$router.replace(`/suite-schema/${schemaUpdate.suiteId}`)
    }
  }
}
</script>
